
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
} from "bootstrap-vue";
import { Ref } from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import vSelect from "vue-select";

@Component({
  name: "GroupsFilters",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
  },
})
export default class GroupsFilters extends Vue {
  @Prop({ required: true }) codeFilter!: Ref<string | null>;
  @Prop({ required: true }) statusFilter!: Ref<boolean | null>;
  @Prop({ required: true }) statusOptions!: Array<{
    label: string;
    value: boolean;
  }>;

  reduceValue(val: { label: string; value: any }) {
    return val.value;
  }
}
